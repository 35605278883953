<template>
    <div class="simulate-financing fill-height">

      <v-row class="fill-height">
        <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 pt-0">
              <div class="mt-0 pt-0">
                <h1 class="text-center today-regular">FINANCIAR</h1>
              </div>
            </v-col>
            <v-col cols="12" md="5" lg="5">

              <div class="px-2 pb-6">
                <v-form
                    ref="form"
                    lazy-validation>

                  <currency-input :value="total_amount + ''" :options="{
                        locale: 'en-US',
                        currency: 'USD',
                        currencyDisplay: 'symbol',
                        hideCurrencySymbolOnFocus: false,
                        hideGroupingSeparatorOnFocus: false,
                        hideNegligibleDecimalDigitsOnFocus: false,
                        autoDecimalDigits: false,
                        autoSign: false,
                        useGrouping: true,
                        accountingSign: true
                      }"
                      :label="'Valor a financiar'"
                      v-on:change="calculateFee($event, 1)"
                      v-on:blur="validateData"
                      :rules="[rules.minValue, rules.maxValue]"/>

                  <v-row class="align-center">
                    <v-col cols="7" md="6">
                      <v-select
                          :items="items"
                          :rules="[v => !!v || 'Selecciona el campo']"
                          label="Cuotas"
                          required
                          outlined
                          dense
                          color="primary"
                          v-model="fee_number"
                          v-on:change="calculateFee($event, 0)"
                          class="select-field text-center select-vardi" style="font-size: 20px;"
                          align="center"></v-select>
                    </v-col>
                    <v-col cols="5" md="6">
                      <p class="primary--text today-medium" style="font-size: 20px;">{{ typeFeeNumber }}</p>
                    </v-col>
                  </v-row>

                  <p class="today-light">Mín {{ minFeeNumber }} {{ typeFeeNumber }} - Máx {{ maxFeeNumber }} {{ typeFeeNumber }}</p>

                  <h3 class="today-regular">Tasa de interés</h3>
                  <h2 class="primary--text today-regular">{{ rate }} % {{ rateType }}</h2>

                </v-form>
              </div>

            </v-col>
            <v-col cols="12" md="7" lg="7">

              <v-card class="mx-auto px-1 pb-4"
                      max-width="90%"
                      outlined
                      style="border-color: #757575; border-radius: 12px;">

                <v-list class="transparent">
                  <v-list-item>
                    <v-list-item-title>
                      <h2 class="primary--text wrap-text today-regular" style="font-size: 1.35em;">Valor primera cuota</h2>
                    </v-list-item-title>
                    <v-list-item-title class="text-right">
                      <h2 class="primary--text today-regular">$ {{ formatCurrency(firstFeeValue) }}</h2>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>

                <v-list class="transparent mt-0">
                  <v-list-item dense>
                    <v-list-item-title>
                      <h2 class="mb-0 wrap-text today-light">Cuota amortizada</h2>
                    </v-list-item-title>
                    <v-list-item-title class="text-right">
                      <h2 class="mb-0 today-light line-height-1-2">$ {{ formatCurrency(amortizedFee) }}</h2>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-title>
                      <h2 class="mb-0 wrap-text today-light">Estudio de crédito</h2>
                    </v-list-item-title>
                    <v-list-item-title class="text-right">
                      <h2 class="mb-0 today-light line-height-1-2">$ {{ formatCurrency(creditStudy) }}</h2>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-title>
                      <h2 class="mb-0 today-light wrap-text">Seguro</h2>
                    </v-list-item-title>
                    <v-list-item-title class="text-right">
                      <h2 class="mb-0 today-light line-height-1-2">$ {{ formatCurrency(insuranceValue) }}</h2>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>


                <v-row justify="center">
                  <v-col cols="8">
                    <v-btn
                      color="white"
                      class="text-capitalize mt-10 primary--text today-medium"
                      block
                      @click="goContactData"
                      style="font-size: 1rem;">
                      Plan de pago
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row justify="center" class="mt-0">
                  <v-col cols="8">
                    <v-btn
                        color="primary"
                        class="text-capitalize today-medium"
                        elevation="0"
                        block
                        style="font-size: 1rem;"
                        @click="dialog = !dialog">
                      Financiar
                    </v-btn>
                  </v-col>
                </v-row>

              </v-card>

            </v-col>
          </v-row>

        </v-container>

      </v-col>
    </v-row>

      <onboarding-modal :dialog="dialog"></onboarding-modal>

  </div>

</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import OnboardingModal from "@/components/Layouts/OnboardingModal";
import NavBarPage from "@/components/Layouts/NavBarPage";
import CurrencyInput from '@/components/CurrencyInput'


export default {
  name: 'SimulateFinancing',
  components: {LeftSideImage, NavBarPage, OnboardingModal, CurrencyInput},
  data() {
    return {
      dialog: false,
      items: [2],
      firstFeeValue: 0,
      creditStudy: 0,
      insurance: 0,
      insuranceValue: 0,
      iva: 0,
      ivaValue: 0,
      othersFeeValue: 0,
      amortizedFee: 0,
      administration: 10000,
      fee_value: 0,
      fee_number: 2,
      typeFeeNumber: '',
      rate: 1.25,
      rateType: '',
      total_amount: 115000,
      minFeeNumber: 0,
      maxFeeNumber: 0,
      minAmount: 0,
      maxAmount: 0,
      settings: [],
      rules: {
        required: value => !!value || 'Ingresa la información',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 digitos',
        counterMin: value => value.length == 10 || 'Cantidad minima 10 digitos',
        minValue: value => parseInt(value.replaceAll(',', '').replace('$', '')) >= this.minAmount || 'Monto mínimo $ ' + this.formatCurrency(this.minAmount),
        maxValue: value => parseInt(value.replaceAll(',', '').replace('$', '')) <= this.maxAmount || 'Monto maximo $ ' + this.formatCurrency(this.maxAmount)
      },
    }
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem('settings'));
    console.log('SimulateFinancing', this.settings);
    console.log('Valor de compra: ', this.total_amount);
    console.log('Cantidad de productos: ', 1);

    this.dataInit();
  },
  methods: {
    goContactData() {
      this.$router.push('/datos-de-contacto');
    },
    dataInit() {
      this.settings.credit_config.forEach((value) => {
        switch (value.key) {
          case "type_fee_number":
            this.typeFeeNumber = value.value;
            break;
          case "min_fee_number":
            this.minFeeNumber = parseInt(value.value);
            break;
          case "max_fee_number":
            this.maxFeeNumber = parseInt(value.value);
            break;
          case "min_amount":
            this.minAmount = parseInt(value.value);
            break;
          case "max_amount":
            this.maxAmount = parseInt(value.value);
            break;
          case "rate":
            this.rate = parseFloat(value.value);
            break;
          case "rate_type":
            this.rateType = value.value;
            break;
          case "administration":
            this.administration = parseInt(value.value);
            break;
          case "iva":
            this.iva = parseFloat(value.value);
            break;
          case "credit_study":
            this.creditStudy = parseInt(value.value);
            break;
          case "credit_insurance":
            this.insurance = parseFloat(value.value);
            break;
          default:
            console.log("NO existe: ", value.value);
        }

      })

      for (let i = parseInt(this.minFeeNumber); i <= parseInt(this.maxFeeNumber); i++) {
        this.items.push(i);
      }
    },
    calculateFee(event, isTotalAmount) {
      if(isTotalAmount) {
        this.total_amount = parseInt(event, 10);
      } else {
        this.fee_number = event;
      }


      /*let _rate = this.rate / 100;
      this.fee_value = _rate / (1 - Math.pow(1 + _rate, -this.fee_number));
      this.fee_value = this.fee_value * this.total_amount;*/

      let format_rate = this.rate / 100;
      this.fee_value = (this.total_amount / (1 + format_rate)) * ((Math.pow(1 + format_rate, this.fee_number) * format_rate) / ((Math.pow(1 + format_rate, this.fee_number) - 1)));

      this.insuranceValue = this.total_amount * (this.insurance / 100);
      this.ivaValue = (this.creditStudy + this.insuranceValue) * (this.iva / 100);

      this.amortizedFee = this.fee_value;
      this.firstFeeValue = this.amortizedFee + this.creditStudy + this.insuranceValue + this.ivaValue;
      this.othersFeeValue = this.amortizedFee + this.administration;


      console.log('total_amount', this.total_amount);

    },
    formatCurrency: function(value) {
      var formatter = new Intl.NumberFormat('es-ES', {
        style: 'decimal',
        minimumFractionDigits: 0
      });
      return formatter.format(parseInt(value, 10));
    },
    validateData() {
      console.log(this.total_amount);
    }
  }
}
</script>
<style scoped>

.v-toolbar__content {
  justify-content: left !important;
}

.v-list-item--dense {
  min-height: 30px;
}

.v-btn {
  letter-spacing: 0 !important;
  border-radius: 6px;
}

.select-vardi > div .v-select__selections {
  background-color: #ffd100 !important;
}
.v-text-field input {
  max-width: 1% !important;
}

</style>